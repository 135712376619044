import { library, icon } from '@fortawesome/fontawesome-svg-core'
import {
    faAddressCard,
    faArrowLeft,
    faArrowRight,
    faCaretDown,
    faCertificate,
    faCheck,
    faClipboardCheck,
    faClock,
    faClockRotateLeft,
    faEarListen,
    faEarthAsia,
    faEarthEurope,
    faEnvelope,
    faEye,
    faHeart,
    faImages,
    faInfoCircle,
    faLungs,
    faMagnifyingGlassLocation,
    faMapLocationDot,
    faNotdef,
    faPeopleGroup,
    faPlus,
    faSearch,
    faSeedling,
    faShoppingCart,
    faSitemap,
    faStopwatch,
    faTimes,
    faTree,
    faUser,
} from '@fortawesome/free-solid-svg-icons'

// import {
//     faFacebookF,
// } from '@fortawesome/free-brands-svg-icons'

library.add(
    faAddressCard,
    faArrowLeft,
    faArrowRight,
    faCaretDown,
    faCertificate,
    faCheck,
    faClipboardCheck,
    faClock,
    faClockRotateLeft,
    faEarListen,
    faEarthAsia,
    faEarthEurope,
    faEnvelope,
    faEye,
    // faFacebookF,
    faHeart,
    faImages,
    faInfoCircle,
    faLungs,
    faMagnifyingGlassLocation,
    faMapLocationDot,
    faNotdef,
    faPeopleGroup,
    faPlus,
    faSearch,
    faSeedling,
    faStopwatch,
    faShoppingCart,
    faSitemap,
    faTimes,
    faTree,
    faUser,
)

const missing = faIcon('notdef')

export function faIcon (iconName, prefix = 'fas') {
    const svg = icon({ prefix, iconName })

    if (!svg) console.warn(`Missing icon: ${prefix} ${iconName}`)

    return svg ? svg : missing
}
